<template>
<div>
<h3 class="text-right">{{ data.district }}</h3>
<div class="text-right">
  <b-button @click="viewMap" pill class="loc-btn mr-2">افتح في خرائط جوجل <img src="@/assets/images/icons/gmaps.svg" :height="height"></b-button>
  <b-button class="box-shadow mr-2" variant="light" pill>مشاركة</b-button>
  <b-button :to="{name: 'favourite', params:{plotId: $route.params.plotId}, query:{plan:$route.query.plan, coords: $route.query.coords, meter_sq: $route.query.meter_sq}}" class="box-shadow" variant="light" pill>حفظ</b-button>
</div>
<b-row class="fs-13 text-right mt-3">
<b-col md="5">
  <span class="d-block mb-2" dir="rtl" style="color:#f00;">{{ numberFormat(planArea) }}</span>
  <span class="d-block mb-2">{{ plotNo }}</span>
  <span class="d-block mb-2">{{ this.$route.query.plan }}</span>
  <span v-if="plan_detail.district != undefined" class="d-block mb-2">{{ plan_detail.district }}</span>

</b-col>
<b-col md="7">
  <span class="d-block mb-2">{{ $t('meter_sq') }}</span>
  <span class="d-block mb-2">{{ $t('plot_no') }}</span>
  <span class="d-block mb-2">{{ $t('plan_no') }}</span>
  <span v-if="plan_detail.district != undefined" class="d-block mb-2">{{ $t('district') }}</span>
</b-col>
</b-row>
<div class="text-right mt-4">
  <router-link :to="{name: 'feedback', params:{plotId: $route.params.plotId}, query:{plan: $route.query.plan, coords: $route.query.coords, meter_sq: $route.query.meter_sq}}"><b-badge pill  class="pd-10" variant="danger">{{ $t('send_feedback') }}<b-icon icon="pencil-fill"></b-icon></b-badge></router-link>
</div>
</div>
</template>

<script>
import utils from "@/helper/utils.js"

export default {
  name: 'PlotWithNoData',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      height: "18px"
    }
  },
  computed: {
    plan() {
      return this.$store.state.plan.plan.properties
    },
    planArea() {
      return this.$route.query.meter_sq || this.plan.Area
    },
    plotNo() {
      return this.$route.params.plotId || this.plan.Plot_No
    },
    plan_detail() {
      return this.$store.state.plan.plan_detail
    },
    lngLat() {
      //return this.$store.state.plan.plan.lngLat
      if(this.$route.query.coords != undefined) {
        let coords = this.$route.query.coords.split(",").reverse().join(",")
        return coords
      }
      return ""
    }
  },
  components: {

  },
  methods: {
    numberFormat(val) {
      return utils.numberFormat(val)
    },
    viewMap() {
      //location.replace(`https://www.google.com/maps/search/?api=1&query=${this.lngLat.lat},${this.lngLat.lng}`)
      window.open(`https://www.google.com/maps/search/?api=1&query=${this.lngLat}`, "_blank")
    },
    getPlanDetail() {
      this.$store.dispatch("plan/fetchPlanDetail", {meta:{city_id: this.$route.query.city_id, plan_no: this.$route.query.plan}})
    }
  },
  created() {
    this.getPlanDetail()
  },
  updated() {
    //this.getPlanDetail()
  }
}
</script>