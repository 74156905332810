<template>
    <b-container fluid class="search-content p-100">
      <div>
      <b-tabs content-class="mt-3" align="right" active-nav-item-class="active-tab-link">
        <b-tab title="التفاصيل" active title-link-class="tab-link">
          <plot-with-data v-if="plot.plot" :data="plot" />
          <plot-with-no-data v-if="!plot.plot" :data="plot" />
        </b-tab>
      </b-tabs>
      </div>
      <download-app v-if="!authenticated" />
    </b-container>
</template>
<script>
import PlotWithData from '@/components/PlotWithData.vue'
import PlotWithNoData from '@/components/PlotWithNoData.vue'
import DownloadApp from '@/components/DownloadApp.vue'
export default {
  title: "Mokhatat Plot",
  data() {
    return {
      height: "18px"
    }
  },
  components: {
    PlotWithData,
    PlotWithNoData,
    DownloadApp
  },
  watch: {
    '$route.query'() {
      this.fetchPlot()
    }
  },
  computed: {
    plot() {
      return this.$store.state.plan.plot
    },
    authenticated() {
     return this.$store.state.AppActiveUser.first_name != undefined
   },
   shared() {
     return this.$route.query.share_id != undefined
   }
  },
  methods: {
    fetchPlot() {
      let loader = this.$loading.show({opacity: 0.9})
      const payload = {
        meta: {
          planNo: this.$route.query.plan,
          plotNo: this.$route.params.plotId,
          city: this.$route.params.city_id
        }
      }
      this.$store.dispatch("plan/fetchPlot", payload)
      .then(() => {
        loader.hide()
      })
      .catch(() => {
        loader.hide()
      })
    },
    openDownloadApp() {
      this.$bvModal.show('download_app')
    }
  },
  created() {
    this.fetchPlot()
    if(this.shared && this.authenticated) {
      //update shared plot
      this.$store.dispatch("plan/recordShareView", this.$route.query.share_id)
    }
  },
  updated() {
    //this.fetchPlot()
  },
  mounted() {
    if (this.shared && !this.authenticated)
      this.openDownloadApp()
  }
}
</script>

<style lang="scss">
#map {
  width: 100%;
  height: 100vh;
}
</style>
