<template>
  <b-modal centered id="download_app" title="" :hide-footer=true content-class="signup-content" header-class="signup-header">
     <h1 class="text-right mb-4">مخطط أفضل على التطبيق</h1>
     <div>
       <p dir="rtl" class="text-right fs-18">  احصل على أدق المعلومات العقارية الموثوقة على جهازك، اختر نوع المتجر المناسب ليتم تحميله مباشرة</p>
         <div class="text-center">
          <b-button @click="playStore" class="btn-rounded black text-left align-top" size="lg" variant="outline-primary">
            <span class="d-inline-block pb-1 mr-1">
              <img src="@/assets/images/icons/play-store.svg" class="btn-svg">
            </span>
            <span class="d-inline-block">
              <span class="fs-8 btn-intro">GET IT ON</span>
              <span class="d-block">Google Play</span>
            </span>
          </b-button>
          <b-button @click="appStore" class="btn-rounded black text-left align-top" size="lg" variant="outline-primary">
            <span class="d-inline-block pb-1 mr-1">
              <img src="@/assets/images/icons/apple-logo.svg" class="btn-svg">
            </span>
            <span class="d-inline-block">
              <span class="fs-8 btn-intro">GET IT ON</span>
              <span class="d-block">App Store</span>
            </span>
          </b-button>
        </div>
     </div>
   </b-modal>
</template>

<script>
export default {
  name: 'DownloadApp',
  methods: {
    appStore() {
      //location.replace("https://apps.apple.com/us/app/mokhatat/id1536398678")
      window.open("https://apps.apple.com/us/app/mokhatat/id1536398678", "_blank")
    },
    playStore() {
      //location.replace("https://play.google.com/store/apps/details?id=com.mokhatat")
      window.open("https://play.google.com/store/apps/details?id=com.mokhatat", "_blank")
    }
  }
}
</script>
