<template>
  <div>
    <div class="mb-4 text-right">
      <b-button pill @click="downloadPlotDetails($route.query.plan, data.plot)" class="mr-2"><img src="@/assets/images/icons/download.png" :height="height"> تحميل تفاصيل القطعة</b-button>
      <b-button pill @click="viewMap" class="loc-btn">افتح في خرائط جوجل <img src="@/assets/images/icons/gmaps.svg" :height="height"></b-button>
    </div>
    <h3 class="text-right">{{ data.district }}</h3>
    <div class="text-right">
      <b-badge pill class="pd-10 m-badge-outline m-width-100 font-sfns" variant="light">{{ meterSquare }}</b-badge>
      <b-badge pill class="pd-10 m-width-100" variant="primary">{{ $t(classification) }}</b-badge>
    </div>
    <b-row class="fs-13 text-right mt-3">
      <b-col cols="5">
        <span v-if="data.last_transaction_price_per_sq_meter" class="d-block mb-2" dir="rtl" style="color:#f00;">{{ pricePerMeterSquare }} ر.س</span>
        <span class="d-block mb-2 font-sfns">{{ data.number_of_trasactions }}</span>
        <span class="d-block mb-2">{{ data.plot }}</span>
        <span class="d-block mb-2">{{ $route.query.plan }}</span>
        <span v-if="data.number_of_trasactions != 0" class="d-block mb-2">{{ data.district }}</span>
        <span v-if="data.number_of_trasactions != 0" class="d-block mb-2">{{ data.city[0]["name"] }}</span>
        <span class="d-block mb-2">{{ data.meter_sq }}</span>
      </b-col>
      <b-col cols="7">
        <span v-if="data.last_transaction_price_per_sq_meter" class="d-block mb-2">{{ $t('price_per_sq_meter') }}</span>
        <span class="d-block mb-2">{{ $t('number_of_transactions') }}</span>
        <span class="d-block mb-2">{{ $t('plot_no') }}</span>
        <span class="d-block mb-2">{{ $t('plan_no') }}</span>
        <span v-if="data.number_of_trasactions != 0" class="d-block mb-2">{{ $t('district') }}</span>
        <span v-if="data.number_of_trasactions != 0" class="d-block mb-2">{{ $t('city') }}</span>
        <span class="d-block mb-2">{{ $t('meter_sq') }}</span>
      </b-col>
    </b-row>
    <div class="pd-20">
      <div class="mb-4 mt-5 text-right">
        <b-button v-if="data.recent_deals[0]" variant="outline-secondary" pill @click="downloadTransactionDetails($route.query.plan, data.plot)" class="mr-2">
          <img src="@/assets/images/icons/download_light.png" :height="height"> تحميل الصفقة
        </b-button>
        <span class="text-right ml-2">الصفقات ابتداءً من الأحدث</span>
      </div>
      <div v-for="item in data.recent_deals" :key="item.id" class="m-table-cover fs-14 mb-4">
        <b-row class="m-table-h">
          <b-col>{{ data.plot }}</b-col>
          <b-col>{{ $t('plot_no') }}</b-col>
        </b-row>
        <b-row class="m-table-data">
          <b-col>{{ item.en_date }}</b-col>
          <b-col>{{ $t('en_date')}}</b-col>
        </b-row>
        <b-row class="m-table-data">
          <b-col>{{ item.hij_date }}</b-col>
          <b-col>{{ $t('hij_date') }}</b-col>
        </b-row>
        <b-row class="m-table-data">
          <b-col>{{ $t(classification) }}</b-col>
          <b-col>{{ $t('classification') }}</b-col>
        </b-row>
        <b-row class="m-table-data">
          <b-col>{{ $t(sub) }}</b-col>
          <b-col>{{ $t('sub_classification') }}</b-col>
        </b-row>
        <b-row class="m-table-data">
          <b-col dir="rtl">{{ numberFormat(item.price_per_sq_meter) }}  ر.س</b-col>
          <b-col>{{ $t('price_per_sq_meter') }}</b-col>
        </b-row>
        <b-row class="m-table-b">
          <b-col dir="rtl">{{ numberFormat(item.amount) }}  ر.س</b-col>
          <b-col>{{ $t('amount') }}</b-col>
        </b-row>
      </div>
    </div>
    <div class="text-right pb-4 mbottom-200">
      <router-link :to="{name: 'feedback', params:{plotId: $route.params.plotId}, query:{plan: $route.query.plan, coords: $route.query.coords, meter_sq: $route.query.meter_sq}}"><b-badge pill  class="pd-10" variant="danger">{{ $t('send_feedback') }}<b-icon icon="pencil-fill"></b-icon></b-badge></router-link>
    </div>
  </div>
</template>

<script>
import utils from "@/helper/utils.js"
import {url} from "../axios";

export default {
  name: 'PlotWithData',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      height: "18px"
    }
  },
  components: {

  },
  computed: {
    classification() {
      if(this.data.classification == "Residential")
        return 'residential'
      return 'commercial'
    },
    sub() {
      if (this.data.sub_classification != undefined)
        return utils.subClassification(this.data.sub_classification.name)
      return "na"
    },
    meterSquare() {
      return  utils.numberFormat(this.$route.query.meter_sq)
      //return "لايوجد"
    },
    pricePerMeterSquare() {
      let price = utils.numberFormat(this.data.last_transaction_price_per_sq_meter)
      return price
      //if (!isNaN(price)) return price
      //return "لايوجد"
    },
    lngLat() {
      //return this.$store.state.plan.plan.lngLat
      if(this.$route.query.coords != undefined) {
        let coords = this.$route.query.coords.split(",").reverse().join(",")
        return coords
      }
      return ""
    }
  },
  methods: {
    numberFormat(val) {
      return utils.numberFormat(val)
    },
    viewMap() {
      //location.replace(`https://www.google.com/maps/search/?api=1&query=${this.lngLat.lat},${this.lngLat.lng}`)
      window.open(`https://www.google.com/maps/search/?api=1&query=${this.lngLat}`, "_blank")
    },
    downloadPlotDetails(planNumber, plotNumber) {
      window.open(`${url}customer/plot-detail-pdf?planNo=${planNumber}&plotNo=${plotNumber}`, "_blank")
    },
    downloadTransactionDetails(planNumber, plotNumber) {
      window.open(`${url}v1/customer/transactions-pdf?planNo=${planNumber}&plotNo=${plotNumber}`, "_blank")
    }
  }
}
</script>
